import React from 'react'

const Banner = (props) => (
    <section id="banner" className="major">
        <div className="inner">
            <header className="major">
                <h1>Let's find your dream home.</h1>
            </header>
            <div className="content">
                <p>3 Large heritage mansions for rent in Victoria British Columbia. <br />
                Converted into suites, with 24 units in total.</p>
                <ul className="actions">
                    <li><a href="#one" className="button next scrolly">Get Started</a></li>
                </ul>
            </div>
        </div>
    </section>


)

export default Banner
