import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import Banner from '../components/Banner'

import rockland01 from '../assets/images/galt/rockland01.jpg'
import hesket01 from '../assets/images/hesket/hesket-811.jpg'
import mcneill01 from '../assets/images/ince/2021-mcneill.jpg'
import pic04 from '../assets/images/pic04.jpg'
import pic05 from '../assets/images/pic05.jpg'
import pic06 from '../assets/images/pic06.jpg'

class HomeIndex extends React.Component {
    render() {

        return (
            <Layout>
                <Helmet
                    title="Victoria Heritage Apartments | Apartments Rentals | Victoria, BC"
                    meta={[
                        { name: 'description', content: 'Victoria Heritage Apartments. Victoria, BC apartment rentals. Rockland, Oak Bay apartments. Suites for Rent in Victoria, BC.' },
                        { name: 'keywords', content: 'Victoria Heritage Apartments. Victoria, BC apartment rentals. Rockland, Oak Bay apartments. Suites for Rent in Victoria, BC.' },
                    ]}
                >
                <link href="https://fonts.googleapis.com/css?family=Great+Vibes|Roboto"  rel="stylesheet"/>
                </Helmet>

                <Banner />

                <div id="main">
                    <section id="one" className="tiles">
                        <article style={{backgroundImage: `url(${rockland01})`}}>
                            <header className="major">
                                <h3>Galthouse</h3>
                                <p>With a total suite count of 12 units, Galt House is 3 stories high and is roughly 10,000 square feet in size.</p>
                            </header>
                            <Link to="/galthouse" className="link primary"></Link>
                        </article>
                        <article style={{backgroundImage: `url(${hesket01})`}}>
                            <header className="major">
                                <h3>Hesket</h3>
                                <p>Situated at 811 St. Charles Avenue, Hesket is an exquisite heritage mansion converted into 10 homely suites.</p>
                            </header>
                            <Link to="/hesket" className="link primary"></Link>
                        </article>
                        <article style={{backgroundImage: `url(${mcneill01})`}}>
                            <header className="major">
                                <h3>Ince Manor</h3>
                                <p>Ince is within walking distance to the ocean, and is a 10 minute transit commute away from downtown Victoria.</p>
                            </header>
                            <Link to="/ince" className="link primary"></Link>
                        </article>
                        <article style={{backgroundImage: `url(${pic04})`}}>
                            <header className="major">
                                <h3>Coming Soon...</h3>
                                <p>Something amazing is coming!</p>
                            </header>
                            <Link to="#" className="link primary"></Link>
                        </article>
                    </section>
                    <section id="two">
                        <div className="inner">
                            <header className="major">
                                <h2>Get in touch.</h2>
                            </header>
                            <p>Looking for your dream home? Inquire below.</p>
                        </div>
                    </section>
                </div>

            </Layout>
        )
    }
}

export default HomeIndex
